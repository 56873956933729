const ruPages = {
    admin: {
        title: "Администрирование",
        users: {
            title: "Пользователи",
            userTitle: "Пользователь",
            userDetails: "Пользователь",
            onlyRegister: "Только зарегистрированные",
            toUserView: "Зайти под пользователем",
            fields: {
                name: "Имя",
                phone: "Телефон",
                email: "E-mail",
                lastvisitDate: "Был",
                registerDate: "Создан",
                details: "Дополнительно",
            },
            errors: {
                getUser: "Не удалось получить информацию по пользователю!",
                setGameStatus: "Не удалось изменить статус мероприятия!",
            },
            success: {
                setGameStatus: 'Статус изменен на "%s"',
            },
            games: {
                title: "Мероприятия",
                noRecords: "Нет мероприятий",
                statusDelete: "Удалить",
                addGame: "Добавить мероприятие",
                fields: {
                    date: "Создан (последний визит)",
                    status: "Статус",
                    quests: "Задания",
                    eqs: "Предметы",
                    params: "Параметры",
                    gameId: "Мероприятие",
                },
            },
        },
        start: {
            title: "Точки входа",
            editTitle: "Редактировать точку входа",
            addTitle: "Новая точка входа",
            fields: {
                gameId: "Мероприятие",
                price: "Цена",
                value: "Код",
                link: "Ссылка",
            },
            success: {
                save: "Точка входа отредактирована",
                remove: "Точка входа удалена",
            },
            errors: {
                save: "Не удалось отредактировать точку входа!",
                remove: "Не удалось удалить точку входа!",
            },
            delete: {
                title: "Удалить точку входа",
                text: 'Вы действительно ходите удалить точку входа для мероприятия "%s"?',
                okText: "Да, удалить",
            },
        },
    },
    home: {
        top: {
            text: `<h1>Маршруты по Владивостоку в твоем смартфоне</h1>
            <p>Соединяем реальный и виртуальный мир</p>
            <p>Прогулки самостоятельно и в группах</p>
            <p>От семейного формата до 18+</p>`,
        },
        game: {
            title: "Наши маршруты",
            go: "Подробнее",
            continue: "Продолжить",
            submit: "Записаться",
            price: "%s руб.",
            price0: "Бесплатно",
            priceTooltip: "Цена за прохождение маршрута %s руб.",
        },
        gameDetails: {
            actions: {
                pay: "Оплатить",
                go: "Начать",
                login: "Начать",
            },
        },
        gameSubmit: {
            title: "Записаться",
            submit: "Записаться",
            fields: {
                name: "Имя",
                phone: "Телефон",
                email: "Почта",
            },
            success: {
                sendContact: "Спасибо. Мы свяжемся с вами как только появятся новости",
            },
            errors: {
                name: "Укажите свое имя",
                phoneOrEmail: "Укажите номер телефона или e-mail",
                email: "Укажите корректный e-mail",
                sendContact: "Не удалось отправить запрос на сервер",
            },
        },
        contacts: {
            title: "Наши контакты",
        },
        form: {
            title: "Оставьте контакты, если хотите организовать прогулку для своей группы",
            text: "Заполняя форму, вы соглашаетесь с ",
            agreement: "политикой обработки персональных данных",
            submit: "Отправить",
            fields: {
                name: "Имя",
                phone: "Телефон",
                email: "Почта",
            },
            success: {
                sendContact: "Спасибо. Скоро мы свяжемся с вами",
            },
            errors: {
                name: "Укажите свое имя",
                phoneOrEmail: "Укажите номер телефона или e-mail",
                email: "Укажите корректный e-mail",
                sendContact: "Не удалось отправить запрос на сервер",
            },
        },
    },
    main: {
        loaderText: "Идет загрузка данных. Подождите...",
    },
    info: {
        title: "Информация",
    },
    payment: {
        title: "Оплата",
        redirect: "Перенаправление",
        checkWait: "Идет проверка статуса платежа. Подождите",
        errors: {
            startPayment: "Не удалось сформировать счет на оплату. Попробуйте позже!",
            checkPayment: "Не удалось проверить статус оплаты. Попробуйте позже или обратитесь в нашу тех. поддержку!",
        },
        status: {
            notActive: {
                text: "Кнопка «Оплатить» перенаправит на страницу приема платежей.",
                action: "Оплатить",
            },
            waitConfirmPay: {
                text: "Мы пока не получили платеж. Возможно, деньги от банка еще не пришли.",
                contact: "Обратиться за помощью можно в нашу техподдержку:",
                details: "Детали платежа",
                refresh: "Проверить",
                action: "Оплатить снова",
            },
            active: {
                text: "Оплата прошла успешно. Можете приступать к прохождению маршрута",
                action: "Начать",
            },
        },
        confirm: {
            agreement: "Я соглашаюсь с офертой",
            title: "Договор оферты",
            text: "Подробности о сервисах сайта, оплате и возврате средств – в ",
            link: "договоре оферты",
            errorConfirm: "Не удалось загрузить текст договора оферты",
        },
        price: {
            priceTitle: "Цена за прохождение маршрута - ",
            price: "%s руб.",
            price0: "Бесплатно",
        },
    },
    reset: {
        title: "Сброс настроек",
        confirm: {
            title: "Сбросить все настройки",
            text: "Вы уверены, что хотите сбросить все настройки и удалить полученный прогресс?",
            okText: "Да, удалить все",
        },
        reInitDb: {
            success: "Настройки сброшены",
        },
    },
    user: {
        profile: {
            title: "Профиль",
            fields: {
                name: "Имя",
                email: "E-mail",
                phone: "Телефон",
                game: "Маршрут",
                status: "Статус",
            },
        },
        login: {
            title: "Авторизация",
            toLogin: "Войти",
            toRegistration: "Зарегистрироваться",
            orText: "У вас еще нет учетной записи? Тогда можно:",
            fields: {
                login: "email или номер телефона",
                password: "Пароль",
            },
            notRegister: {
                title: "Регистрация",
                text: "Вы, пока, не зарегистрированы в нашем приложении.",
                okText: "Зарегистрироваться",
                noText: "Позже",
            },
            errors: {
                not_match: "Пароль не подходит",
                bloked: "Ваша учетная запись заблокирована",
                login: "Не удалось авторизоваться",
            },
        },
        registration: {
            title: "Регистрация",
            toRegistration: "Сохранить",
            required: "Поля помеченные * - обязательны к заполнению",
            agreementAppTitle: "Обработка персональных данных",
            fields: {
                name: "Имя",
                email: "E-mail",
                phone: "Номер телефона",
                password: "Пароль",
                password2: "Пароль еще раз",
                agreementApp: "Я даю согласие на обработку моих персональных данных",
            },
            success: {
                save: "Вы успешно зарегистрировались",
            },
            errors: {
                passwordLength: "Пароль должен иметь не менее 6 символов",
                passwordPattern:
                    "Пароль должен содержать ТОЛЬКО латинские буквы (минимум одну) и арабские цифры  (минимум одну)",
                confirmPassword: "Пароль и подтверждение не совпадают.",
                save: "Не удалось Вас зарегистрировать. Обратитесь в службу поддержки!",
                not_found: "Запись не найдена!",
                register: "Вы уже зарегистрированы в нашем приложении!",
                already_email: "Другой пользователь уже использует этот E-mail",
                already_phone: "Другой пользователь уже использует этот номер телефона",
                agreementApp: "Не удалось загрузить текст согласия на обработку персональных данных",
            },
        },
        adult: {
            title: "18+",
            text: "Данный маршрут имеет возрастное ограничение 18+. Чтобы продолжить, вы должны подтвердить, что вам уже исполнилось 18 лет",
            no: "НЕТ, я младше 18 лет",
            yes: "ДА, мне уже есть 18 лет!",
            noText: "К сожалению, вы не можете поучавствовать в данном маршруте. Но у нас есть и другие.",
            toMainSite: "Посмотреть другие",
            errors: {
                saveAdult: "Не удалось сохранить данные о вашем возрасте. Попробуйте позже!",
            },
        },
        blocked: {
            text: "Ваша учетная запись была заблокирована. Для уточнения, пожалуйста, свяжитесь с нашей техничекой поддержкой.",
        },
    },
};

export default ruPages;
